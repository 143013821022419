import React, { useState, useEffect } from "react";
import { Input, Button, Modal, Table, Popconfirm, Switch } from "antd";
 // Switch 추가

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment-timezone';
import axios from "axios";
import "./Team.scss"; // 스타일 파일 불러오기


moment.tz.setDefault('Asia/Seoul');
moment.locale('ko');

const Team = () => {
  
  const [startDate, setStartDate] = useState(null);


  const [events, setEvents] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [admin, setAdmin] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false); // 수정 모드 구분 상태
  const [editingEventId, setEditingEventId] = useState(null); // 수정 중인 이벤트의 ID
  const [newEvent, setNewEvent] = useState({
    eventName: "",
    eventStartDate: null, // 대회 시작일 추가
    eventEndDate: null, // 대회 종료일 추가
    eventDate: null, // 접수 마감일 추가
    totalParticipants: "",
    bankAccount: "",
    bankName: "",
    eventStatus: false // 대회 진행 여부 상태 추가
  });

  useEffect(() => {
    if (!localStorage.getItem("admin")) {
      window.location.href = "/";
    }
    setAdmin(localStorage.getItem("admin"));

    // 이벤트 데이터를 가져오는 함수
    const fetchEvents = async () => {
      try {
        const response = await axios.get("https://xn--bb0bp9it32a1kcc8ci3c.com/api/events");
        setEvents(response.data);
      } catch (error) {
        console.error("Error fetching events:", error);
      }
    };

    fetchEvents();
  }, []);

  const fetchEvents = async () => {
    try {
      const response = await axios.get("https://xn--bb0bp9it32a1kcc8ci3c.com/api/events");
      setEvents(response.data);
    } catch (error) {
      console.error("Error fetching events:", error);
    }
  };

  const columns = [
    {
      title: '#',
      label: '#',
      dataIndex: 'index',
      key: 'index',
      render: (_, __, index) => index + 1
    },
    {
      title: '대회 이름',
      label: 'Event Name',
      dataIndex: 'eventName',
      key: 'eventName',
    },
    {
      title: '접수 시작일',
      label: 'Event Start Date',
      dataIndex: 'eventStartDateKst',
      key: 'eventStartDate',
      render: (text) => (text ? moment(text).format("YYYY.MM.DD HH:mm") : ""), // 날짜와 시간 표시
    },
    {
      title: '접수 종료일',
      label: 'Event End Date',
      dataIndex: 'eventEndDateKst',
      key: 'eventEndDate',
      render: (text) => (text ? moment(text).format("YYYY.MM.DD HH:mm") : ""), // 날짜와 시간 표시
    },
    {
      title: '대회날짜',
      label: 'Event Date',
      dataIndex: 'eventDateKst',
      key: 'eventDate',
      render: (text) => (text ? moment(text).format("YYYY.MM.DD HH:mm") : ""), // 날짜와 시간 표시
    },
    {
      title: '총 인원수',
      label: 'Total Participants',
      dataIndex: 'totalParticipants',
      key: 'totalParticipants',
    },
    {
      title: '참석자 수',
      label: 'Attendees Count',
      dataIndex: 'participantsCount',
      key: 'participantsCount',
    },
    {
      title: '대회 상태', // 대회 진행 여부 상태 추가
      key: 'eventStatus',
      render: (_, record) => (
        <Switch checked={record.eventStatus} disabled /> // 상태 스위치 (비활성화)
      )
    },
    {
      title: '수정', // 수정 버튼 추가
      key: 'modify',
      render: (_, record) => (
        <Button type="primary" onClick={() => handleRowClick(record)}>수정</Button>
      )
    },
    {
      title: '삭제', // 삭제 버튼 추가
      key: 'delete',
      render: (_, record) => (
        <Popconfirm
          title="정말로 삭제하시겠습니까?"
          onConfirm={() => handleDeleteEvent(record.id)} // 삭제 이벤트 처리 함수
          okText="네"
          cancelText="아니오"
        >
          <Button type="primary" danger>삭제</Button>
        </Popconfirm>
      )
    }
  ];

  const handleDeleteEvent = async (id) => {
    try {
      await axios.delete(`https://xn--bb0bp9it32a1kcc8ci3c.com/api/events/${id}`);
      setEvents(events.filter(event => event.id !== id)); // 삭제된 이벤트를 리스트에서 제거
    } catch (error) {
      console.error("Error deleting event:", error);
    }
  };

  const handleRowClick = (event) => {
    setIsEditMode(true);
    setEditingEventId(event.id);
    setNewEvent({
      eventName: event.eventName,
      eventStartDate: event.eventStartDateKst, // DatePicker에 맞게 moment로 변환
      eventEndDate: event.eventEndDateKst, // DatePicker에 맞게 moment로 변환
      eventDate: event.eventDateKst, // DatePicker에 맞게 moment로 변환 (접수 마감일)
      totalParticipants: event.totalParticipants,
      bankAccount: event.bankAccount,
      bankName: event.bankName,
      eventStatus: event.eventStatus // 상태 값도 설정
    });
    setIsModalOpen(true);
  };

  const handleAddEvent = async () => {
    try {
      const formattedEvent = {
          ...newEvent
        };

      console.log(formattedEvent);
      // const formattedEvent = {
      //   ...newEvent,
      //   eventStartDate: newEvent.eventStartDate ? newEvent.eventStartDate.format("YYYY-MM-DD HH:mm:ss") : null,
      //   eventEndDate: newEvent.eventEndDate ? newEvent.eventEndDate.format("YYYY-MM-DD HH:mm:ss") : null,
      //   eventDate: newEvent.eventDate ? newEvent.eventDate.format("YYYY-MM-DD HH:mm:ss") : null // 접수 마감일
      // };
      if (isEditMode) {
        // 수정 모드일 경우, PUT 요청으로 데이터 수정
        await axios.put(`https://xn--bb0bp9it32a1kcc8ci3c.com/api/events/${editingEventId}`, formattedEvent);
        setEvents(events.map(event => event.id === editingEventId ? { ...event, ...formattedEvent } : event));
        fetchEvents();
      } else {
        // 추가 모드일 경우, POST 요청으로 데이터 추가
        const response = await axios.post("https://xn--bb0bp9it32a1kcc8ci3c.com/api/events", formattedEvent);
        setEvents([...events, { id: response.data.id, participantsCount: 0, ...formattedEvent }]);
        fetchEvents();
      }
      
      // 폼 초기화 및 모달 닫기
      setNewEvent({
        eventName: "",
        eventStartDate: null,
        eventEndDate: null,
        eventDate: null, // 접수 마감일 초기화
        totalParticipants: "",
        bankAccount: "",
        bankName: "",
        eventStatus: false
      });
      setIsModalOpen(false);
      setIsEditMode(false); // 수정 모드 종료
    } catch (error) {
      console.error("Error adding/editing event:", error);
    }
  };

  return (
    <div className="team-page" style={{ padding: '20px' }}>
      <Modal
        title={isEditMode ? "대회 수정" : "대회 추가"}
        open={isModalOpen}
        onOk={handleAddEvent}
        onCancel={() => {
          setIsModalOpen(false);
          setIsEditMode(false); // 모달이 닫히면 수정 모드 종료
        }}
      >
        <div style={{ marginBottom: '10px' }}>
          <label>대회 이름:</label>
          <Input
            value={newEvent.eventName}
            onChange={(e) => setNewEvent({ ...newEvent, eventName: e.target.value })}
            style={{ marginBottom: '10px' }}
          />
        </div>
        <div style={{ marginBottom: '10px' }}>
          <label>접수 시작일:</label>
         
          <DatePicker
            
            selected={newEvent&&moment(newEvent.eventStartDate).toDate()}
             onChange={(date) => setNewEvent({ ...newEvent, eventStartDate: date })}
            showTimeSelect // 시간을 선택할 수 있게 설정
            dateFormat="YYYY.MM.DD"
            timeFormat="HH:mm" showTimeSelect
            dateFormat="yyyy.MM.dd HH:mm"
            timeFormat="HH:mm"
            timeIntervals={15} // 시간 선택 간격
            placeholderText="날짜 및 시간 선택"
            style={{ marginBottom: '10px', width: '100%' }}
          />
        </div>
        <div style={{ marginBottom: '10px' }}>
          <label>접수 종료일:</label>
          <DatePicker
          selected={newEvent&&moment(newEvent.eventEndDate).toDate()}
             onChange={(date) => setNewEvent({ ...newEvent, eventEndDate: date })}
            showTimeSelect // 시간을 선택할 수 있게 설정showTimeSelect
            dateFormat="yyyy.MM.dd HH:mm"
            timeFormat="HH:mm"
            timeIntervals={15} // 시간 선택 간격
            placeholderText="날짜 및 시간 선택"
            style={{ marginBottom: '10px', width: '100%' }}
          />
        </div>
        <div style={{ marginBottom: '10px' }}>
          <label>대회날짜:</label> {/* 접수 마감일 추가 */}
          <DatePicker
         selected={newEvent&&moment(newEvent.eventDate).toDate()}
         onChange={(date) => setNewEvent({ ...newEvent, eventDate: date })}
            dateFormat="yyyy.MM.dd"
            style={{ marginBottom: '10px', width: '100%' }}
          />
        </div>
        <div style={{ marginBottom: '10px' }}>
          <label>총 인원수:</label>
          <Input
            value={newEvent.totalParticipants}
            onChange={(e) => setNewEvent({ ...newEvent, totalParticipants: e.target.value })}
            style={{ marginBottom: '10px' }}
          />
        </div>
        <div style={{ marginBottom: '10px' }}>
          <label>입금 계좌:</label>
          <Input
            value={newEvent.bankAccount}
            onChange={(e) => setNewEvent({ ...newEvent, bankAccount: e.target.value })}
            style={{ marginBottom: '10px' }}
          />
        </div>
        <div style={{ marginBottom: '10px' }}>
          <label>은행명:</label>
          <Input
            value={newEvent.bankName}
            onChange={(e) => setNewEvent({ ...newEvent, bankName: e.target.value })}
            style={{ marginBottom: '10px' }}
          />
        </div>
        <div style={{ marginBottom: '10px' }}>
          <label>대회 상태:</label>
          <Switch
            checked={newEvent.eventStatus}
            onChange={(checked) => setNewEvent({ ...newEvent, eventStatus: checked })}
          />
        </div>
      </Modal>
      <div style={{ fontSize: '24px', fontWeight: 'bold', marginBottom: '20px' }}>팀 구성</div>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
        <div>
          <Input
            style={{ width: '200px', marginRight: '10px' }}
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
          />
          <Button onClick={() => {}}>검색</Button>
        </div>
        <Button type="primary" onClick={() => {
          setIsModalOpen(true);
          setIsEditMode(false); // 추가 모드로 설정
        }}>대회 추가하기</Button>
      </div>
      <Table
        dataSource={events}
        columns={columns}
        rowKey="id"
        onRow={(record) => ({
          onClick: () => {}, // 행 클릭 시 이벤트 핸들러 호출
        })}
      />
    </div>
  );
};

export default Team;
