import React, { useState, useEffect, useContext } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Layout,Input, Menu } from 'antd';
 
import HomePage from './pages/HomePage';
import AboutPage from './pages/AboutPage';
import AdminLogin from './AdminLogin';
import Users from './Admin/Users';
import Pati from './Admin/Pati';
import Team from './Admin/Team';
import './App.css';

const { Header, Content, Footer } = Layout;


const AdminLeftMenu = () =>{
  //const history = useHistory(); // useHistory 훅을 사용하여 history 객체를 얻음

  const handleMenuClick = (key) => {
    // 메뉴 아이템 클릭 시 호출되는 함수
    if (key === 'team-user-list') {
      //history.push('/admin/users'); // '/user-list'로 이동
      window.location.href= '/admin/pati';
    }  else if (key === 'user-list') {
      //history.push('/admin/webinows'); // '/webinar-list'로 이동
      window.location.href= '/admin/users';
    }else if (key === 'team-list') {
      //history.push('/admin/webinows'); // '/webinar-list'로 이동
      window.location.href= '/admin/team';
    } else if (key === 'logout') {
      localStorage.removeItem('loggedIn'); 
      localStorage.removeItem('partner');  
      localStorage.removeItem('admin');  
      
      window.location.href= "/admin";
    }

    
  };

  return <Menu 
  onClick={({ key }) => handleMenuClick(key)} 
  theme="dark" mode="vertical" style={{paddingTop:30,width:250, height: "100vh"}}>
  <div style={{color:"white", fontSize:20, padding:20, fontWeight:"bold", marginBottom: 20 }}>관리자 어드민</div>
   
  <Menu.Item key="team-list">대회 목록</Menu.Item>    
  <Menu.Item key="team-user-list">대회 등록 인원 관리</Menu.Item>  
  <Menu.Item key="user-list">회원 목록</Menu.Item>  
  <Menu.Item key="logout">로그아웃</Menu.Item>
  </Menu>
}

function App() {

  const [admin, setAdmin] = useState(false);

  const [userId, setUserId] = useState("");

  useEffect(() => { 
    setUserId(localStorage.getItem("loggedIn"));  
    setAdmin(localStorage.getItem("admin")); 
  }, []);


  const path = window.location.pathname;

  return (
    <Router>
      <Layout> 
        <Content style={{ padding: '0 0' }}>
          <div className={"site-layout-content"+(path.includes("/admin")&&admin?" appadmin":"")}>
          {path.includes("/admin")&&admin?<AdminLeftMenu />:null}
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/about" element={<AboutPage />} />
              <Route path="/admin" element={<AdminLogin />} />

              <Route path="/admin/pati" element={<Pati />} />
              <Route path="/admin/users" element={<Users />} />
              <Route path="/admin/team" element={<Team />} />
            </Routes>
          </div>
        </Content>
       </Layout>
    </Router>
  );
}

export default App;