import React, { useState,useEffect } from "react";
import { div, Icon, Input, Button, Modal } from "antd";
//import RoleConst from "../../../consts/role";

import "./AdminLogin.scss"; 

const AdminLogin  = () => {
 

  const [id, setId] = useState("");

  const [pwd, setPwd] = useState("");



  useEffect(() => {
    if(localStorage.getItem('admin') ){
      //location.href="/admin/users";
    } 

  }, []);


  const adminLogin = () =>{  
    if(id=="admin" && pwd =="admin"){
      localStorage.setItem('admin', true); 
      Modal.success({title:"로그인에 성공했습니다."})
      window.location.href="/admin/users";
    }else{
      Modal.info({title:"로그인에 실패했습니다."})
    }
    // Meteor.call('admin.login',{user_id:id, user_pwd: pwd}, (error, result) => {
    //   if (error) {
    //     console.log("Error:", error);
    //   } else {
    //     console.log("result:", result);
    //     if(result){
    //       localStorage.setItem('admin', true); 
    //       Modal.success({title:"로그인에 성공했습니다."})
    //       location.href="/admin/users";
    //     }else{
    //       Modal.info({title:"로그인에 실패했습니다."})
    //     }
    //   }
    // }); 
  }
  return (
    <div className="admin-signin-page">
     
      <div className="title">서비스 어드민</div>
      <div>
        <Input placeholder="아이디" style={{
          marginTop: 10, 
          width:250, height: 40
        }}
        value = {id}
        onChange={(e)=>{
          setId(e.target.value);
        }}
        />
      </div>
      <div>
        <Input type="password" placeholder="패스워드"
        value = {pwd}
        onChange={(e)=>{
          setPwd(e.target.value);
        }}
        style={{
           marginTop: 10, 
          width:250, height: 40
        }}
        />
      </div>
      <div>
        <Button 
          onClick={()=>{
            adminLogin();
          }}
          className="primary" style={{     marginTop: 10, 
          width:250, height: 40, background: "#149991", color:"white",
          borderRadius: 8}}>
          로그인
        </Button>
      </div>
    </div>
  );
}
 

export default AdminLogin;