import React, { useState, useEffect } from 'react';
import { Button, Layout, Menu, Modal, Form, Input, Select, message } from 'antd';
import HomeIcon from '@material-ui/icons/Home';
import EditIcon from '@material-ui/icons/Edit'; 
import FlagIcon from '@material-ui/icons/Flag';
import DocumentIcon from '@material-ui/icons/Description';
import PersonIcon from '@material-ui/icons/Person';
import Slider from "react-slick";
import DaumPostcode from 'react-daum-postcode';
import moment from 'moment-timezone';
import axios from 'axios';
import './HomePage.scss';


import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


moment.tz.setDefault('Asia/Seoul');

moment.locale('ko');
const { Header, Content, Footer } = Layout;
const { Option } = Select;

const HomePage = () => {
  const [selectedMenu, setSelectedMenu] = useState('1');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isLoginModalVisible, setIsLoginModalVisible] = useState(false); // 로그인 팝업
  const [isLoggedIn, setIsLoggedIn] = useState(false); // 로그인 상태 관리
  const [isRegisterModalVisible, setIsRegisterModalVisible] = useState(false); // 회원가입 모달 상태
  const [isEditModalVisible, setIsEditModalVisible] = useState(false); // 모달 상태 관리

  const [selectedEvent, setSelectedEvent] = useState(null);
  const [events, setEvents] = useState([]);
  const [requestEvents, setRequestEvents] = useState([]);
  const [userInfo, setUserInfo] = useState(null);
  const [form] = Form.useForm();
  const [passwordForm] = Form.useForm();
  const [loginForm] = Form.useForm(); // 로그인 폼 관리
  const [requestform] = Form.useForm(); // 로그인 폼 관리
  const [isEventDetailModalVisible, setIsEventDetailModalVisible] = useState(false); // 모달의 상태


  
  const [isAddressSearchVisible, setIsAddressSearchVisible] = useState(false);
  const [address, setAddress] = useState('');
  const [isPasswordModalVisible, setIsPasswordModalVisible] = useState(false); // 비밀번호 찾기 모달 상태 추가


  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    const storedUser = localStorage.getItem('user');
  
    if (storedToken && storedUser) {
      setIsLoggedIn(true); // 로그인 상태를 복원
      setUserInfo(JSON.parse(storedUser)); // 사용자 정보를 복원
    }
  
    fetchEvents();

    if(isLoggedIn){
      fetchRequestEvents();
    }
  }, []);

  useEffect(()=>{
    console.log(selectedMenu);
    if(selectedMenu==4){
      if(isLoggedIn){
        fetchUserInfo();
        console.log("fetchUserInfo");
      }

    }
  }, [selectedMenu])
 
  const PasswordResetModal = ({ isPasswordModalVisible, setIsPasswordModalVisible, setIsLoginModalVisible }) => {
    const [resetForm] = Form.useForm();
    const [isCodeSent, setIsCodeSent] = useState(false); // 인증번호 전송 여부
    const [isVerified, setIsVerified] = useState(false); // 인증번호 검증 여부
    const [loading, setLoading] = useState(false); // 로딩 상태
  
    // 인증번호 전송
    const handleSendCode = async () => {
      try {
        setLoading(true);
        const values = await resetForm.validateFields(['phoneNumber']);
        const { phoneNumber } = values;
  
        // 서버에 인증번호 요청
        const response = await axios.post('https://xn--bb0bp9it32a1kcc8ci3c.com/api/send-reset-code', { phoneNumber });
        if (response.status === 200) {
          message.success('인증번호가 발송되었습니다.');
          setIsCodeSent(true);
        }
      } catch (error) {
        message.error(error.response?.data?.message || '인증번호 발송 실패');
      } finally {
        setLoading(false);
      }
    };
  
    // 인증번호 검증
    const handleVerifyCode = async () => {
      try {
        setLoading(true);
        const values = await resetForm.validateFields(['phoneNumber', 'verificationCode']);
        const { phoneNumber, verificationCode } = values;
  
        // 서버에 인증번호 검증 요청
        const response = await axios.post('https://xn--bb0bp9it32a1kcc8ci3c.com/api/verify-reset-code', {
          phoneNumber,
          verificationCode,
        });
        if (response.status === 200) {
          message.success('인증번호가 확인되었습니다.');
          setIsVerified(true);
        }
      } catch (error) {
        message.error(error.response?.data?.message || '인증번호 검증 실패');
      } finally {
        setLoading(false);
      }
    };
  
    // 비밀번호 재설정
    const handleResetPassword = async () => {
      try {
        setLoading(true);
        const values = await resetForm.validateFields(['phoneNumber', 'verificationCode', 'newPassword']);
        const { phoneNumber, verificationCode, newPassword } = values;
  
        // 서버에 비밀번호 재설정 요청
        const response = await axios.post('https://xn--bb0bp9it32a1kcc8ci3c.com/api/reset-password', {
          phoneNumber,
          verificationCode,
          newPassword,
        });
  
        if (response.status === 200) {
          message.success('비밀번호가 재설정되었습니다.');
          setIsPasswordModalVisible(false);
          setIsLoginModalVisible(true); // 로그인 모달로 전환
        }
      } catch (error) {
        message.error(error.response?.data?.message || '비밀번호 재설정 실패');
      } finally {
        setLoading(false);
      }
    };
  
    return (
      <Modal
        title="비밀번호 찾기"
        visible={isPasswordModalVisible}
        footer={null}
        onCancel={() => setIsPasswordModalVisible(false)}
      >
        <Form form={resetForm} layout="vertical">
          {/* 휴대폰 번호 입력 */}
          <Form.Item
            name="phoneNumber"
            label="휴대폰 번호"
            rules={[{ required: true, message: '휴대폰 번호를 입력하세요.' }]}
          >
            <Input placeholder="휴대폰 번호를 입력하세요" disabled={isCodeSent} />
          </Form.Item>
  
          {/* 인증번호 입력 */}
          {isCodeSent && (
            <Form.Item
              name="verificationCode"
              label="인증번호"
              rules={[{ required: true, message: '인증번호를 입력하세요.' }]}
            >
              <Input placeholder="인증번호를 입력하세요" disabled={isVerified} />
            </Form.Item>
          )}
  
          {/* 새 비밀번호 입력 */}
          {isVerified && (
            <Form.Item
              name="newPassword"
              label="새 비밀번호"
              rules={[{ required: true, message: '새 비밀번호를 입력하세요.' }]}
            >
              <Input.Password placeholder="새 비밀번호를 입력하세요" />
            </Form.Item>
          )}
  
          <div style={{ marginTop: '20px', textAlign: 'center' }}>
            {!isCodeSent && (
              <Button type="primary" onClick={handleSendCode} loading={loading}>
                인증번호 보내기
              </Button>
            )}
            {isCodeSent && !isVerified && (
              <Button type="primary" onClick={handleVerifyCode} loading={loading}>
                인증번호 확인
              </Button>
            )}
            {isVerified && (
              <Button type="primary" onClick={handleResetPassword} loading={loading}>
                비밀번호 재설정
              </Button>
            )}
          </div>
        </Form>
      </Modal>
    );
  };

  const EditAddressModal = ({ visible, setVisible, userInfo, setUserInfo }) => {
    const [form] = Form.useForm();
  
    const handleSaveAddress = async (values) => {
      try {
        const response = await axios.put('https://xn--bb0bp9it32a1kcc8ci3c.com/api/update-address', {
          userId: userInfo.id,
          address: values.address
        });
  
        // 주소 수정 성공 시
        message.success('주소가 성공적으로 변경되었습니다.');
        setUserInfo({ ...userInfo, address: values.address });
        setVisible(false); // 모달 닫기
      } catch (error) {
        message.error('주소 수정에 실패했습니다.');
        console.error(error);
      }
    };
  
    return (
      <Modal
        title="주소 수정"
        visible={visible}
        onCancel={() => setVisible(false)}
        footer={null}
      >
        <Form
          form={form}
          layout="vertical"
          initialValues={{ address: userInfo.address }}
          onFinish={handleSaveAddress}
        >
          <Form.Item
            name="address"
            label="새로운 주소"
            rules={[{ required: true, message: '주소를 입력하세요.' }]}
          >
            <Input placeholder="주소를 입력하세요" />
          </Form.Item>
  
          <div style={{ textAlign: 'center', marginTop: '20px' }}>
            <Button type="primary" htmlType="submit" style={{ width: '100px', marginRight: '10px' }}>
              저장
            </Button>
            <Button type="default" onClick={() => setVisible(false)} style={{ width: '100px' }}>
              취소
            </Button>
          </div>
        </Form>
      </Modal>
    );
  };

  const RegisterModal = ({ isRegisterModalVisible, setIsRegisterModalVisible, setIsLoginModalVisible }) => {
    const [registerForm] = Form.useForm();
  
    // 회원가입 처리 함수
    const handleRegister = () => {
      registerForm.validateFields().then(async (values) => {
        try {
          console.log({
            phoneNumber: values.phoneNumber,
            ssn: values.ssn,
            name: values.name,
            address: values.address
          });
          const response = await axios.post('https://xn--bb0bp9it32a1kcc8ci3c.com/api/register', {
            phoneNumber: values.phoneNumber,
            ssn: values.ssn,
            name: values.name,
            address: values.address
          });
  
          const { token, user } = response.data;
  
          // 로컬 스토리지에 토큰과 사용자 정보 저장
          localStorage.setItem('token', token);
          localStorage.setItem('user', JSON.stringify(user));
          setIsLoggedIn(true);
  
          message.success('회원가입 성공!');
          setIsRegisterModalVisible(false); // 회원가입 모달 닫기
          setIsLoginModalVisible(false); // 로그인 모달 닫기
        } catch (error) {
          message.error('회원가입 실패: ' + error.response.data.message);
        }
      }).catch(info => {
        console.log('Validate Failed:', info);
      });
    };
  
    return (
      <Modal
        title="회원가입"
        open={isRegisterModalVisible}
        onCancel={() => setIsRegisterModalVisible(false)}
        footer={null}
      >
        <Form form={registerForm} layout="vertical">
          <Form.Item
            name="name"
            label="이름"
            rules={[{ required: true, message: '이름을 입력하세요!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="phoneNumber"
            label="휴대폰 번호"
            rules={[{ required: true, message: '휴대폰 번호를 입력하세요!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="ssn"
            label="주민번호 앞 6자리"
            rules={[{ required: true, message: '주민번호 앞 6자리를 입력하세요!' }]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            name="address"
            label="주소"
            rules={[{ required: true, message: '주소를 입력해주세요.' }]}
          >
            <Input />
          </Form.Item>
          <div style={{ textAlign: 'center', marginTop: '20px' }}>
            <Button type="primary" style={{ width: '100px', marginRight: '10px' }} onClick={handleRegister}>
              회원가입
            </Button>
            <Button type="default" style={{ width: '100px' }} onClick={() => setIsRegisterModalVisible(false)}>
              취소
            </Button>
          </div>
        </Form>
      </Modal>
    );
  };
  

  const fetchEvents = async () => {
    try {
      const response = await axios.get("https://xn--bb0bp9it32a1kcc8ci3c.com/api/events");
      console.log(response.data);
      setEvents(response.data);
    } catch (error) {
      console.error("Error fetching events:", error);
    }
  };


  const fetchUserInfo = async () => {
    try {

      console.log("fetchUserInfo");
      const userId = localStorage.getItem('userId');
      
      console.log(userId);

      const registrationData = {
        userId: userId
      };
      axios.post(`https://xn--bb0bp9it32a1kcc8ci3c.com/api/userInfo`, registrationData)
        .then(response => {
          console.log(response.data);

          setUserInfo(response.data&&response.data.user);

        })
        .catch(error => {
          console.error('Error registering user:', error);
          if(error&&error.response&&error.response.data&&error.response.data.message){
            message.error(error.response.data.message);
            localStorage.clear();
            setIsLoggedIn(false);
            setUserInfo(null);
            setSelectedMenu("1");
            message.success('로그아웃되었습니다.');
            
          }
        });

     
     
    } catch (error) {
      console.error("Error fetching events:", error);

      localStorage.clear();
      setIsLoggedIn(false);
      setUserInfo(null);
      setSelectedMenu("1");
      message.success('로그아웃되었습니다.');
    }
  };

  

  const fetchRequestEvents = async () => {
    try {  
      console.log(userInfo.phoneNumber);
      

      const response = await axios.get(`https://xn--bb0bp9it32a1kcc8ci3c.com/api/user/${userInfo.phoneNumber}/events`);
      console.log(response.data);
      setRequestEvents(response.data);
    } catch (error) {
      console.error("Error fetching events:", error);
    }
  };

  
  // 로그인 체크
  const checkLogin = () => {
    if (!isLoggedIn) {
      message.warning("로그인이 필요합니다.");
      setIsLoginModalVisible(true); // 로그인 팝업 표시
      return false;
    }
    return true;
  };


  const handleLogout = () => {
    // 로그아웃 처리 로직 (예: 로컬 스토리지에서 토큰 삭제)
    //localStorage.removeItem('token');
    //localStorage.removeItem('user');
    localStorage.clear();
    setIsLoggedIn(false);
    setUserInfo(null);
    setSelectedMenu("1");
    message.success('로그아웃되었습니다.');
     
  };

  // 메뉴 클릭시 로그인 체크
  const handleMenuClick = (key) => {
    if ((key === '3' || key === '4') && !checkLogin()) {
      return;
    }
    if(key==='3'){
      fetchRequestEvents();
    }
    setSelectedMenu(key);

  };

  const handleOk = () => {
    requestform.validateFields().then(values => {
      const registrationData = {
        name: values.name,
        ssn: values.ssn,
        phone: `${values.phone0}-${values.phone1}-${values.phone2}`,
        address: values.address,
        registration_time: new Date().toISOString()
      };
      axios.post(`https://xn--bb0bp9it32a1kcc8ci3c.com/api/events/${selectedEvent.id}/users`, registrationData)
        .then(response => {
          console.log('User registered successfully:', response.data);
          message.success("참가 신청이 완료되었습니다.");
          setIsModalVisible(false);
          form.resetFields();
          fetchEvents(); // 참가자가 등록된 후 이벤트 목록을 다시 불러옵니다.
        })
        .catch(error => {
          console.error('Error registering user:', error);
          if(error&&error.response&&error.response.data&&error.response.data.message){
            message.error(error.response.data.message);
          }
        });
    }).catch(info => {
      console.log('Validate Failed:', info);
    });
  };

  const handleLogin =  () => {
    loginForm.validateFields().then(async  values => {
      // 로그인 처리 로직
       


      const response = await axios.post('https://xn--bb0bp9it32a1kcc8ci3c.com/api/login', {
        phoneNumber: values.username,
        ssn: values.password 
      });
 

      const { token, user } = response.data;

      // 로컬 스토리지에 토큰과 사용자 정보 저장
      localStorage.setItem('token', token);
      localStorage.setItem('user', JSON.stringify(user));
      localStorage.setItem('userId', user.id);
      setUserInfo(user);
      // 예: axios로 로그인 API 호출 후 로그인 상태 설정
      setIsLoggedIn(true); // 성공적으로 로그인 되었다고 가정
      setIsLoginModalVisible(false); // 로그인 팝업 닫기
      message.success('로그인 성공!');
    }).catch(info => {
      if(info&&info.response&&info.response.data&&info.response.data.message){
        message.error(info.response.data.message);
      }
      console.log('Validate Failed:', info);
    });
  };
  const handleRegisterClick = () => {
    setIsLoginModalVisible(false); // 로그인 모달 닫기
    setIsRegisterModalVisible(true); // 회원가입 모달 열기
  };
  const handlePasswordModal = () => {
    setIsPasswordModalVisible(true);
    setIsLoginModalVisible(false); // 비밀번호 찾기 모달 열 때 로그인 모달 닫기
  };




  const NextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block" }}
        onClick={onClick}
      />
    );
  };
  
  // Custom PrevArrow component
  const PrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block"  }}
        onClick={onClick}
      />
    );
  };

  const handleRegister = () => {
    // 여기서 가입 처리 로직 추가 가능
    message.success('가입 처리 완료!');
    setIsLoginModalVisible(false);
  };


  const sliderSettings1 = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />
  };
  const sliderSettings2 = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow:  1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />
  };
 

  const renderContent = () => {


  const handlePasswordChange = async (values) => {
    console.log("??");
    try {
      const response = await axios.post('https://xn--bb0bp9it32a1kcc8ci3c.com/api/change-password', {
        userId: userInfo.id,  // 현재 로그인한 사용자 ID
        newPassword: values.password,
      });
      message.success('비밀번호가 성공적으로 변경되었습니다.');
    } catch (error) {
      message.error('비밀번호 변경에 실패했습니다.');
      console.error(error);
    }
  };

  const showModal = (event) => {
    setSelectedEvent(event);
    setIsEventDetailModalVisible(true); // 모달 열기
  };

  

    switch (selectedMenu) {
      case '1':
        // 신청 가능한 대회 리스트
        // let availableEvents = events.filter(event => 
        //   moment().isBetween(moment(event.eventStartDate), moment(event.eventEndDate))
        // );
        let availableEvents = events;
        // 진행 중인 대회 리스트
        //let ongoingEvents = events.filter(event => moment(event.eventDate).isBefore(moment()) && moment(event.eventEndDate).isAfter(moment()));
        //console.log(availableEvents);
        return (
          <Content className="homepage-content"  >
            <div className="event-section"> 
              {/* 신청 가능한 대회 슬라이더 */}
              <div className="event-slider">
                <h2><EditIcon style={{ fontSize: '20px' }} />신청 가능한 골프 대회</h2>
                {availableEvents&&availableEvents.length>1?<Slider {...sliderSettings1}>
                  {availableEvents.map(event => (
                     <div key={event.id} className="event-card-cover">
                       
                       {event.eventImage?
                      <div  className="event-card" style={{display:"flex", flexDirection:"row"}}>
                        <div><img style={{width:"100%"}} src={event.eventImage} /></div>
                        <div style={{display:"flex", flexDirection:"column", justifyContent:"center"}}>  
                          <Button style={{backgroundColor:"rgba(232, 86, 18)"}} type="primary" onClick={() => handleEventClick(event)}>참가신청</Button>
                          <Button style={{marginTop:20, backgroundColor:"rgba(46, 166, 225)"}} type="primary"  onClick={()=>showModal(event)} >대회안내</Button>
                        </div>
                       
                      </div>
                      :<div  className="event-card" style={{display:"flex", flexDirection:"row"}}>
                         <div style={{width:"100%", paddingLeft:20}}>
                          <div style={{paddingTop:20, fontWeight:"bold"}}>
                            <span>{event.eventName}</span>
                          </div>
                           <p style={{color:"rgba(52, 52, 52)", fontSize:14}}>신청 기간: <span style={{ fontWeight:"bold" }}>{moment(event.eventStartDateKst).format('YYYY.MM.DD HH시')} ~ {moment(event.eventEndDateKst).format('YYYY.MM.DD HH시')}</span></p>
                           <p style={{color:"rgba(52, 52, 52)", fontSize:14}}>대회 날짜: <span style={{ fontWeight:"bold" }}>{moment(event.eventDateKst).format('YYYY.MM.DD')} </span></p>
                        </div>
                        <div style={{display:"flex", flexDirection:"column", justifyContent:"center"}}>  
                          <Button style={{backgroundColor:"rgba(232, 86, 18)"}} type="primary" onClick={() => handleEventClick(event)}>참가신청</Button>
                          <Button style={{marginTop:20, backgroundColor:"rgba(46, 166, 225)"}} type="primary"  onClick={()=>showModal(event)}  >대회안내</Button>
                        </div>
                         
                      </div>}
                    </div>
                  ))}
                </Slider>:
                (availableEvents&&availableEvents.length==1?
                <div> {availableEvents.map(event => (
                  <div key={event.id} className="event-card-cover">
                     
                     {event.eventImage?
                      <div  className="event-card" style={{display:"flex", flexDirection:"row"}}>
                        <div><img style={{width:"100%"}} src={event.eventImage} /></div>
                        <div style={{display:"flex", flexDirection:"column", justifyContent:"center"}}>  
                          <Button style={{backgroundColor:"rgba(232, 86, 18)"}} type="primary" onClick={() => handleEventClick(event)}>참가신청</Button>
                          <Button style={{marginTop:20, backgroundColor:"rgba(46, 166, 225)"}} type="primary"  onClick={()=>showModal(event)}  >대회안내</Button>
                        </div>
                       
                      </div>
                      :<div  className="event-card" style={{display:"flex", flexDirection:"row"}}>
                         <div style={{width:"100%", paddingLeft:20}}>
                          <div style={{paddingTop:20, fontWeight:"bold"}}>
                            <span>{event.eventName}</span>
                          </div>
                           <p style={{color:"rgba(52, 52, 52)", fontSize:14}}>신청 기간: <span style={{ fontWeight:"bold" }}>{moment(event.eventStartDateKst).format('YYYY.MM.DD HH시')} ~ {moment(event.eventEndDateKst).format('YYYY.MM.DD HH시')}</span></p>
                           <p style={{color:"rgba(52, 52, 52)", fontSize:14}}>대회 날짜: <span style={{ fontWeight:"bold" }}>{moment(event.eventDate).format('YYYY.MM.DD')} </span></p>
                        </div>
                        <div style={{display:"flex", flexDirection:"column", justifyContent:"center"}}>  
                          <Button style={{backgroundColor:"rgba(232, 86, 18)"}} type="primary" onClick={() => handleEventClick(event)}>참가신청</Button>
                          <Button style={{marginTop:20, backgroundColor:"rgba(46, 166, 225)"}} type="primary"  onClick={()=>showModal(event)} >대회안내</Button>
                        </div>
                         
                      </div>}
                 </div>
               ))}</div>:null)
                
              }
              </div>
              </div>
              <div className="event-section" style={{height: 350}}>

              {/* 진행 중인 대회 슬라이더
               <div className="event-section" style={{height: 350}}>
                <div className="event-slider">
                <h2><FlagIcon style={{ fontSize: '20px' }} />진행 중인 골프 대회</h2>
                {ongoingEvents&&ongoingEvents>1?<Slider {...sliderSettings2}>
                  {ongoingEvents.map(event => (
                    <div key={event.id} className="event-card-cover">
                      {event.eventImage?
                      <div  className="event-card" style={{display:"flex", flexDirection:"row"}}>
                        <div><img style={{width:"100%"}} src={event.eventImage} /></div>
                        <div style={{display:"flex", flexDirection:"column", justifyContent:"center"}}>  
                          <Button style={{backgroundColor:"rgba(232, 86, 18)"}} type="primary" onClick={() => handleEventClick(event)}>참가신청</Button>
                          <Button style={{marginTop:20, backgroundColor:"rgba(46, 166, 225)"}} type="primary"  >대회안내</Button>
                        </div>
                       
                      </div>
                      :<div  className="event-card" style={{display:"flex", flexDirection:"row"}}>
                        <div style={{width:"100%", paddingLeft:20}}>
                          <div style={{paddingTop:20, fontWeight:"bold"}}>
                            <span>{event.eventName}</span>
                          </div>
                           <p style={{color:"rgba(52, 52, 52)", fontSize:14}}>신청 기간: <span style={{ fontWeight:"bold" }}>{moment(event.eventStartDate).format('YYYY.MM.DD HH시')} ~ {moment(event.eventEndDate).format('YYYY.MM.DD HH시')}</span></p>
                           <p style={{color:"rgba(52, 52, 52)", fontSize:14}}>대회 날짜: <span style={{ fontWeight:"bold" }}>{moment(event.eventDate).format('YYYY.MM.DD')} </span></p>
                        </div>
                        <div style={{display:"flex", flexDirection:"column", justifyContent:"center"}}>  
                          <Button style={{backgroundColor:"rgba(232, 86, 18)"}} type="primary" onClick={() => handleEventClick(event)}>참가신청</Button>
                          <Button style={{marginTop:20, backgroundColor:"rgba(46, 166, 225)"}} type="primary" >대회안내</Button>
                        </div>
                         
                      </div>}
                    </div>
                  ))}
                </Slider>
                :ongoingEvents&&ongoingEvents==1?
                <div>  {ongoingEvents.map(event => (
                  <div key={event.id} className="event-card-cover">
                    {event.eventImage?
                      <div  className="event-card" style={{display:"flex", flexDirection:"row"}}>
                        <div><img style={{width:"100%"}} src={event.eventImage} /></div>
                        <div style={{display:"flex", flexDirection:"column", justifyContent:"center"}}>  
                          <Button style={{backgroundColor:"rgba(232, 86, 18)"}} type="primary" onClick={() => handleEventClick(event)}>참가신청</Button>
                          <Button style={{marginTop:20, backgroundColor:"rgba(46, 166, 225)"}} type="primary"  >대회안내</Button>
                        </div>
                       
                      </div>
                      :<div  className="event-card" style={{display:"flex", flexDirection:"row"}}>
                        <div style={{width:"100%", paddingLeft:20}}>
                          <div style={{paddingTop:20, fontWeight:"bold"}}>
                            <span>{event.eventName}</span>
                          </div>
                           <p style={{color:"rgba(52, 52, 52)", fontSize:14}}>신청 기간: <span style={{ fontWeight:"bold" }}>{moment(event.eventStartDate).format('YYYY.MM.DD HH시')} ~ {moment(event.eventEndDate).format('YYYY.MM.DD HH시')}</span></p>
                           <p style={{color:"rgba(52, 52, 52)", fontSize:14}}>대회 날짜: <span style={{ fontWeight:"bold" }}>{moment(event.eventDate).format('YYYY.MM.DD')} </span></p>
                        </div>
                        <div style={{display:"flex", flexDirection:"column", justifyContent:"center"}}>  
                          <Button style={{backgroundColor:"rgba(232, 86, 18)"}} type="primary" onClick={() => handleEventClick(event)}>참가신청</Button>
                          <Button style={{marginTop:20, backgroundColor:"rgba(46, 166, 225)"}} type="primary"  >대회안내</Button>
                        </div>
                         
                      </div>}
                  </div>
                ))}</div>:null
                }
                
              </div>
               */}
             

              {/* 대회가 없을 경우 */}
              {availableEvents.length === 0  && (
                <div className="no-events">
                  <p>현재 신청 가능한 대회가 없습니다.</p>
                </div>
              )}
                <div><img src="/banner1.png" style={{width:"100%"}}></img></div>
              <div><img src="/banner2.png" style={{width:"100%", marginTop:10}}></img></div>
            </div>
          
          </Content>
        );
      case '2':
        let availableEvents1 = events;
        // 진행 중인 대회 리스트
        
        return (
          <Content className="homepage-content"  >
             
                   
                  {availableEvents1&&availableEvents1.map(event => (
                     <div key={event.id} className="event-card-cover">
                      {event.eventImage?
                      <div  className="event-card" style={{display:"flex", flexDirection:"row"}}>
                        <div><img style={{width:"100%"}} src={event.eventImage} /></div>
                        <div style={{display:"flex", flexDirection:"column", justifyContent:"center"}}>  
                          <Button style={{backgroundColor:"rgba(232, 86, 18)"}} type="primary" onClick={() => handleEventClick(event)}>참가신청</Button>
                          <Button style={{marginTop:20, backgroundColor:"rgba(46, 166, 225)"}} type="primary" onClick={()=>showModal(event)}  >대회안내</Button>
                        </div>
                       
                      </div>
                      :<div  className="event-card" style={{display:"flex", flexDirection:"row"}}>
                        <div style={{width:"100%", paddingLeft:20}}>
                          <div style={{paddingTop:20, fontWeight:"bold"}}>
                            <span>{event.eventName}</span>
                          </div>
                           <p style={{color:"rgba(52, 52, 52)", fontSize:14}}>신청 기간: <span style={{ fontWeight:"bold" }}>{moment(event.eventStartDateKst).format('YYYY.MM.DD HH시')} ~ {moment(event.eventEndDateKst).format('YYYY.MM.DD HH시')}</span></p>
                           <p style={{color:"rgba(52, 52, 52)", fontSize:14}}>대회 날짜: <span style={{ fontWeight:"bold" }}>{moment(event.eventDate).format('YYYY.MM.DD')} </span></p>
                        </div>
                        <div style={{display:"flex", flexDirection:"column", justifyContent:"center"}}>  
                          <Button style={{backgroundColor:"rgba(232, 86, 18)"}} type="primary" onClick={() => handleEventClick(event)}>참가신청</Button>
                          <Button style={{marginTop:20, backgroundColor:"rgba(46, 166, 225)"}} type="primary"  
        onClick={()=>showModal(event)} >대회안내</Button>
                        </div>
                         
                      </div>}
                    </div>
                  ))}
                   
               
                   <div style={{height: 70}}><span>&nbsp;</span></div>
               
          </Content>
        );
      case '3':
       
        let availableEvents2 = requestEvents;
        // 진행 중인 대회 리스트
        
        return (
          <Content className="homepage-content"  >
             
                   
                  {availableEvents2&&availableEvents2.map(event => (
                     <div key={event.id} className="event-card-cover">
                      <div  className="event-card" style={{padding:20}}>
                        <div className="card-header">
                          <span>대회명</span>
                        </div>
                        <div style={{fontWeight:"bold"}}>
                          {event.eventName}  
                        </div>
                        <div>
                          은행명 : {event.bankName}  
                        </div>
                        <div>
                          계좌번호 : {event.bankAccount}
                        </div>
                        <div>
                          입금 확인 유무 :{event.paymentConfirmed==1?"입금 완료":"미확인"}
                        </div>
                      </div>
                    </div>
                  ))}
                   
                <div style={{height: 70}}><span>&nbsp;</span></div>
               
               
          </Content>
        );
      case '4':
        return (<Content className="mypage-content">
       <div className="mypage-info-section">
        <h2 className="section-title">내 정보</h2>
        <div className="user-info">
          <div className="info-item">
            <label>이름:</label>
            <span>{userInfo.name}</span>
          </div>
          <div className="info-item">
            <label>휴대폰번호:</label>
            <span>{userInfo.phoneNumber}</span>
          </div>
          <div className="info-item">
            <label>주민번호:</label>
            <span>{userInfo.ssn}</span>
          </div>
          <div className="info-item">
            <label>주소:</label>
            <span>{userInfo.address}</span>
            
          </div>
          {/* 수정 버튼 */}
          <div className="info-item">
            <label></label>
            <span><Button type="link" onClick={() => setIsEditModalVisible(true)} style={{ marginLeft: '10px' }}>
              수정
            </Button></span>
            
          </div>
          
        </div>
      </div>

        <div className="mypage-password-section">
          <h2 className="section-title">비밀번호 변경</h2>
          <Form form={passwordForm} layout="vertical" onFinish={handlePasswordChange}>
            <Form.Item
              label="새로운 비밀번호"
              name="password"
              rules={[
                { required: true, message: '새로운 비밀번호를 입력하세요.' },
              ]}
            >
              <Input.Password placeholder="새로운 비밀번호" />
            </Form.Item>
            <Form.Item
              label="비밀번호 확인"
              name="passwordConfirm"
              dependencies={['password']}
              hasFeedback
              rules={[
                { required: true, message: '비밀번호를 다시 입력하세요.' },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error('비밀번호가 일치하지 않습니다.')
                    );
                  },
                }),
              ]}
            >
              <Input.Password placeholder="비밀번호 확인" />
            </Form.Item>

            <div className="mypage-button-wrapper">
              <Button type="primary" htmlType="submit" size="large"  >
                비밀번호 변경
              </Button>
            </div>
          </Form>
           {/* 로그아웃 버튼 */}
           <div className="mypage-logout-wrapper">
              <Button type="default" onClick={handleLogout} size="large">
                로그아웃
              </Button>
            </div>
            <div style={{height:60}}>
              &nbsp;
            </div>
        </div>
         {/* 주소 수정 모달 */}
          <EditAddressModal
            visible={isEditModalVisible}
            setVisible={setIsEditModalVisible}
            userInfo={userInfo}
            setUserInfo={setUserInfo}
          />
        </Content>);
      default:
        return <div>Home Content</div>;
    }
  };

  const handleEventClick = (event) => {
    const eventStartDate = moment(event.eventStartDateKst);
    const eventEndDate = moment(event.eventEndDateKst);
    const currentDate = moment();

    // 현재 날짜가 신청 가능 기간 내에 있는지 확인
    if (currentDate.isBetween(eventStartDate, eventEndDate)) {
      // 신청 가능할 때만 이벤트를 설정하고 모달을 엽니다.
      setSelectedEvent(event);
      setIsModalVisible(true);
    } else {
      // 신청 가능 기간이 아닐 경우 경고 메시지 출력
      message.warning(`신청 가능한 날짜는 ${eventStartDate.format('YYYY-MM-DD')}부터 ${eventEndDate.format('YYYY-MM-DD')}까지입니다.`);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
  };

  const handleAddressSearch = (data) => {
    setAddress(data.address);
    setIsAddressSearchVisible(false);
    form.setFieldsValue({ address: data.address });
  };
  const handleDetailCancel = () => {
    setSelectedEvent(null);
    setIsEventDetailModalVisible(false); // 모달 닫기
  };
  const splitPhoneNumber = (phoneNumber) => {
    let phone0 = '';
    let phone1 = '';
    let phone2 = '';
  
    if (phoneNumber.length === 11) {
      // 11자리인 경우 (예: 010-1234-5678)
      phone0 = phoneNumber.slice(0, 3);  // 첫 3자리 (010)
      phone1 = phoneNumber.slice(3, 7);  // 중간 4자리 (1234)
      phone2 = phoneNumber.slice(7);     // 마지막 4자리 (5678)
    } else if (phoneNumber.length === 10) {
      // 10자리인 경우 (예: 02-1234-5678)
      phone0 = phoneNumber.slice(0, 2);  // 첫 2자리 (02)
      phone1 = phoneNumber.slice(2, 6);  // 중간 4자리 (1234)
      phone2 = phoneNumber.slice(6);     // 마지막 4자리 (5678)
    }
  
    return { phone0, phone1, phone2 };
  };
  let phone0 = '';
  let phone1 = '';
  let phone2 = '';
  if(userInfo&&userInfo.phoneNumber){
    ({ phone0, phone1, phone2 } = splitPhoneNumber(userInfo.phoneNumber));

  }
  
  requestform.setFieldsValue({
    name:userInfo&&userInfo.name,
    ssn:userInfo&&userInfo.ssn,
    phone0,
    phone1,
    phone2,
  });

  return (
    <div className="homepage-container">
      <Layout className="homepage-layout">
        <Header className="homepage-header">
          <div className="logo" onClick={()=>{
             setSelectedMenu('1');
          }}>
            <img className="logo" src="/logo.png" alt="Logo" />
          </div>
          <div className="header-title">
            {selectedMenu === '1' ? <h1>홈</h1> : null}
            {selectedMenu === '2' ? <h1>대회 목록</h1> : null}
            {selectedMenu === '3' ? <h1>신청내역</h1> : null}
            {selectedMenu === '4' ? <h1>마이페이지</h1> : null}
          </div>
          <div className="profile-icon" onClick={()=>{
            if(userInfo.id){
              setSelectedMenu('4');
            }else{
              setIsLoginModalVisible(true);
            }
           
            }}>
            <img className="profile-icon" src="/profile-icon.png" alt="Profile Icon" />
          </div>
        </Header>

        <Content className="homepage-content">
          <div className="homepage-content-inner">
            {renderContent()}

            {/* 모달 */}
            <Modal
              visible={isEventDetailModalVisible}
              onCancel={handleDetailCancel}
              footer={null} // 기본 footer 제거
              bodyStyle={{ padding: 0, margin: 0 }} // 모달 패딩 제거
              style={{ top: 0, height: '100vh' }} // 모달을 화면에 꽉 차게
              width="100%" // 모달 너비를 전체로
              centered // 모달을 화면 중앙에 배치
            >
              <img
                src={selectedEvent&&selectedEvent.detailImage}
                alt="대회 안내 이미지"
                style={{ width: "100%", height: "100%", objectFit: "cover" }} // 이미지가 화면에 꽉 차게
              />
            </Modal>
          </div>
        </Content>
        <Footer className="homepage-footer">
          <Menu
            theme="dark"
            mode="horizontal"

         overflowedIndicator={null} 
             defaultSelectedKeys={['1']}
            onClick={(e) => handleMenuClick(e.key)}
            className="homepage-menu"
          >
            <Menu.Item key="1" icon={<div style={{display:"flex",flexDirection:"column", justifyContent:"center", alignItems:"center"}}><HomeIcon style={{ fontSize: '24px' }} /></div>} className={selectedMenu === '1' ? 'selected' : ''}>
              홈
            </Menu.Item>
            <Menu.Item key="2" icon={<div style={{display:"flex",flexDirection:"column", justifyContent:"center", alignItems:"center"}}><FlagIcon style={{ fontSize: '24px' }} /></div>} className={selectedMenu === '2' ? 'selected' : ''}>
              대회목록
            </Menu.Item>
            <Menu.Item key="3" icon={<div style={{display:"flex",flexDirection:"column", justifyContent:"center", alignItems:"center"}}><DocumentIcon style={{ fontSize: '24px' }} /></div>} className={selectedMenu === '3' ? 'selected' : ''}>
              신청내역
            </Menu.Item>
            <Menu.Item key="4" icon={<div style={{display:"flex",flexDirection:"column", justifyContent:"center", alignItems:"center"}}><PersonIcon style={{ fontSize: '24px' }} /></div>} className={selectedMenu === '4' ? 'selected' : ''}>
              마이페이지
            </Menu.Item>
          </Menu>
        </Footer>

        {/* 경기 신청 모달 */}
        <Modal title="경기 신청" open={isModalVisible} footer={null}>
          <Form form={requestform} layout="vertical" name="register">
            <Form.Item name="name" label="이름" rules={[{ required: true, message: '이름을 입력하세요!' }]}>
              <Input  />
            </Form.Item>
            <Form.Item name="ssn" label="주민번호 앞자리" rules={[{ required: true, message: '주민번호 앞자리를 입력하세요!' }]}>
              <Input  />
            </Form.Item>
            <Form.Item label="전화번호">
              <Input.Group compact>
                <Form.Item name="phone0" 
                initialValue={phone0} 
                rules={[{ required: true, message: '전화번호 앞자리를 선택하세요!' }]} noStyle>
                  <Select style={{ width: '30%' }}  >
                    <Option value="02">02</Option>
                    <Option value="010">010</Option>
                    <Option value="011">011</Option>
                    <Option value="016">016</Option>
                    <Option value="017">017</Option>
                    <Option value="018">018</Option>
                    <Option value="019">019</Option>
                  </Select>
                </Form.Item>
                <Form.Item name="phone1" rules={[{ required: true, message: '전화번호를 입력하세요!' }]} noStyle>
                  <Input   style={{ width: '35%' }} />
                </Form.Item>
                <Form.Item name="phone2" rules={[{ required: true, message: '전화번호를 입력하세요!' }]} noStyle>
                  <Input   style={{ width: '35%' }} />
                </Form.Item>
              </Input.Group>
            </Form.Item>
            {/*  <Form.Item name="address" label="주소" rules={[{ required: true, message: '주소를 입력하세요!' }]}>
              <Input value={address} onClick={() => setIsAddressSearchVisible(true)} />
            </Form.Item>
            {isAddressSearchVisible && (
              <Modal title="주소 검색" open={isAddressSearchVisible} footer={null} onCancel={() => setIsAddressSearchVisible(false)}>
                <DaumPostcode onComplete={handleAddressSearch} />
              </Modal>
            )}*/}
           
          </Form>
          <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}>
            <Button
              type="primary"
              style={{ width: '120px', height: '40px', marginRight: '10px', fontSize: '16px' }}
               
              onClick={handleOk}
            >
              경기 신청하기
            </Button>
            <Button
              style={{ width: '120px', height: '40px', fontSize: '16px' }}
              onClick={handleCancel}
            >
              취소
            </Button>
          </div>
        </Modal>

        {/* 로그인 모달 */}
        <Modal
          title="로그인 하기"
          open={isLoginModalVisible}
          footer={null}  // 기존 Modal의 기본 footer 제거
          onCancel={() => setIsLoginModalVisible(false)}
        >
          <Form form={loginForm} layout="vertical" name="login">
            <Form.Item
              name="username"
              label="아이디(휴대폰 번호)"
              rules={[{ required: true, message: '아이디를 입력하세요!' }]}
            >
              <Input placeholder="아이디를 입력하세요" />
            </Form.Item>
            <Form.Item
              name="password"
              label="비밀번호(초기 비밀번호 주민번호 앞 여섯자리)"
              rules={[{ required: true, message: '비밀번호를 입력하세요!' }]}
            >
              <Input.Password placeholder="비밀번호를 입력하세요(초기 비밀번호 주민번호 앞 여섯자리)" />
            </Form.Item>

            <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}>
              <Button
                type="primary"
                style={{ width: '120px', height: '40px', marginRight: '10px', fontSize: '16px' }}
                onClick={handleLogin}
              >
                로그인하기
              </Button>
              <Button
                style={{ width: '120px', height: '40px', fontSize: '16px' }}
                onClick={() => setIsLoginModalVisible(false)}
              >
                취소
              </Button>
            </div>
            
            <div style={{ marginTop: '20px', textAlign: 'center' }}>
              <Button type="link" onClick={handleRegisterClick}>
                아직 회원이 아니신가요? 가입하기
              </Button>
            </div>
            <div style={{ marginTop: '20px', textAlign: 'center' }}>
              <Button type="link" onClick={handlePasswordModal}>
                비밀번호를 잊으셨나요? 비밀번호 찾기
              </Button>
            </div>
          </Form>
        </Modal>
         {/* 회원가입 모달 */}
      <RegisterModal
        isRegisterModalVisible={isRegisterModalVisible}
        setIsRegisterModalVisible={setIsRegisterModalVisible}
        setIsLoginModalVisible={setIsLoginModalVisible} // 회원가입 후 다시 로그인 모달을 띄울 수 있도록
      />
       {/* 비밀번호 찾기 모달 */}
       <PasswordResetModal
        isPasswordModalVisible={isPasswordModalVisible}
        setIsPasswordModalVisible={setIsPasswordModalVisible}
        setIsLoginModalVisible={setIsLoginModalVisible}
      />
      </Layout>
    </div>
  );
};

export default HomePage;
