import React, { useState, useEffect } from "react";
import { Input, Button, Modal, Table, message } from "antd";
import moment from "moment";
import axios from "axios";
import "./Users.scss";

const Users = () => {
  const [users, setUsers] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editUser, setEditUser] = useState(null); // 수정할 유저 정보
  const [newUser, setNewUser] = useState({
    name: "",
    ssn: "",
    phone_number: "",
    address: "",
    created_at: moment().format("YYYY-MM-DD HH:mm:ss"),
    password: "",
  });

  useEffect(() => {
    fetchUsers(); // 처음 페이지 로드 시 유저 정보를 가져옵니다.
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await axios.get("https://xn--bb0bp9it32a1kcc8ci3c.com/api/users");
      setUsers(response.data);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const handleAddUser = async () => {
    try {
      const formattedUser = {
        ...newUser,
        created_at: moment().format("YYYY-MM-DD HH:mm:ss"),
      };
      await axios.post("https://xn--bb0bp9it32a1kcc8ci3c.com/api/users", formattedUser);
      setUsers([...users, formattedUser]);
      setNewUser({
        name: "",
        ssn: "",
        phone_number: "",
        address: "",
        created_at: moment().format("YYYY-MM-DD HH:mm:ss"),
        password: "",
      });
      setIsModalOpen(false);
      message.success("새로운 유저가 성공적으로 추가되었습니다.");
    } catch (error) {
      console.error("Error adding user:", error);
      message.error("유저 추가 중 오류가 발생했습니다.");
    }
  };

  const handleEditUser = async () => {
    try {
      await axios.put(`https://xn--bb0bp9it32a1kcc8ci3c.com/api/users/${editUser.id}`, editUser);
      setUsers(users.map((user) => (user.id === editUser.id ? editUser : user)));
      setIsEditModalOpen(false);
      message.success("유저 정보가 성공적으로 수정되었습니다.");
    } catch (error) {
      console.error("Error editing user:", error);
      message.error("유저 수정 중 오류가 발생했습니다.");
    }
  };

  const handleDeleteUser = async (userId) => {
    try {
      await axios.delete(`https://xn--bb0bp9it32a1kcc8ci3c.com/api/users/${userId}`);
      setUsers(users.filter((user) => user.id !== userId));
      message.success("유저가 성공적으로 삭제되었습니다.");
    } catch (error) {
      console.error("Error deleting user:", error);
      message.error("유저 삭제 중 오류가 발생했습니다.");
    }
  };

  const handleResetPassword = async (userId) => {
    try {
      await axios.put(`https://xn--bb0bp9it32a1kcc8ci3c.com/api/users/${userId}/reset-password`);
      message.success("비밀번호가 초기화되었습니다.");
    } catch (error) {
      console.error("Error resetting password:", error);
      message.error("비밀번호 초기화 중 오류가 발생했습니다.");
    }
  };

  const handleSearch = () => {
    // 필터링 로직을 추가하여 검색
    const filteredUsers = users.filter((user) =>
      user.name.includes(searchText) || user.phone_number.includes(searchText)
    );
    setUsers(filteredUsers);
  };

  const columns = [
    {
      title: "#",
      label: "#",
      dataIndex: "index",
      key: "index",
      render: (_, __, index) => index + 1,
    },
    {
      title: "성명",
      label: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "주민번호 앞자리",
      label: "SSN",
      dataIndex: "ssn",
      key: "ssn",
    },
    {
      title: "연락처",
      label: "Phone",
      dataIndex: "phone_number",
      key: "phone_number",
    },
    {
      title: "주소",
      label: "Address",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "등록 시간",
      label: "Created At",
      dataIndex: "created_at",
      key: "created_at",
      render: (createdAt) => moment(createdAt).format("YYYY-MM-DD HH:mm:ss"),
    },
    {
      title: "액션",
      key: "actions",
      render: (text, record) => (
        <>
          <Button type="primary" onClick={() => { setEditUser(record); setIsEditModalOpen(true); }}>수정</Button>
          <Button danger onClick={() => handleDeleteUser(record.id)} style={{ marginLeft: "10px" }}>삭제</Button>
          <Button onClick={() => handleResetPassword(record.id)} style={{ marginLeft: "10px" }}>비밀번호 초기화</Button>
        </>
      ),
    },
  ];

  return (
    <div className="admin-page" style={{ padding: "20px" }}>
      {/* 유저 추가 모달 */}
      <Modal
        title="유저 추가"
        open={isModalOpen}
        onOk={handleAddUser}
        onCancel={() => setIsModalOpen(false)}
      >
        <div style={{ marginBottom: "10px" }}>
          <label>성명:</label>
          <Input
            value={newUser.name}
            onChange={(e) => setNewUser({ ...newUser, name: e.target.value })}
            style={{ marginBottom: "10px" }}
          />
        </div>
        <div style={{ marginBottom: "10px" }}>
          <label>주민번호 앞자리:</label>
          <Input
            value={newUser.ssn}
            onChange={(e) => setNewUser({ ...newUser, ssn: e.target.value })}
            style={{ marginBottom: "10px" }}
          />
        </div>
        <div style={{ marginBottom: "10px" }}>
          <label>연락처:</label>
          <Input
            value={newUser.phone_number}
            onChange={(e) => setNewUser({ ...newUser, phone_number: e.target.value })}
            style={{ marginBottom: "10px" }}
          />
        </div>
        <div style={{ marginBottom: "10px" }}>
          <label>주소:</label>
          <Input
            value={newUser.address}
            onChange={(e) => setNewUser({ ...newUser, address: e.target.value })}
            style={{ marginBottom: "10px" }}
          />
        </div>
        <div style={{ marginBottom: "10px" }}>
          <label>비밀번호:</label>
          <Input.Password
            value={newUser.password}
            onChange={(e) => setNewUser({ ...newUser, password: e.target.value })}
            style={{ marginBottom: "10px" }}
          />
        </div>
      </Modal>

      {/* 유저 수정 모달 */}
      {editUser && (
        <Modal
          title="유저 수정"
          open={isEditModalOpen}
          onOk={handleEditUser}
          onCancel={() => setIsEditModalOpen(false)}
        >
          <div style={{ marginBottom: "10px" }}>
            <label>성명:</label>
            <Input
              value={editUser.name}
              onChange={(e) => setEditUser({ ...editUser, name: e.target.value })}
              style={{ marginBottom: "10px" }}
            />
          </div>
          <div style={{ marginBottom: "10px" }}>
            <label>주민번호 앞자리:</label>
            <Input
              value={editUser.ssn}
              onChange={(e) => setEditUser({ ...editUser, ssn: e.target.value })}
              style={{ marginBottom: "10px" }}
            />
          </div>
          <div style={{ marginBottom: "10px" }}>
            <label>연락처:</label>
            <Input
              value={editUser.phone_number}
              onChange={(e) => setEditUser({ ...editUser, phone_number: e.target.value })}
              style={{ marginBottom: "10px" }}
            />
          </div>
          <div style={{ marginBottom: "10px" }}>
            <label>주소:</label>
            <Input
              value={editUser.address}
              onChange={(e) => setEditUser({ ...editUser, address: e.target.value })}
              style={{ marginBottom: "10px" }}
            />
          </div>
        </Modal>
      )}

      <div style={{ fontSize: "24px", fontWeight: "bold", marginBottom: "20px" }}>회원 목록</div>
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "20px" }}>
        <div>
          <Input
            style={{ width: "200px", marginRight: "10px" }}
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
          <Button onClick={handleSearch}>검색</Button>
        </div>
        <Button type="primary" onClick={() => setIsModalOpen(true)}>
          유저 추가하기
        </Button>
      </div>
      <Table dataSource={users} columns={columns} rowKey="id" />
    </div>
  );
};

export default Users;
