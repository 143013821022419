import React, { useState, useEffect } from "react";
import { Input, Button, Modal, Table, Select, message } from "antd";
import moment from "moment";
import axios from "axios";
import "./Pati.scss";

const { Option } = Select;

const Pati = () => {
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [users, setUsers] = useState({});
  const [searchText, setSearchText] = useState("");
  const [admin, setAdmin] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newUser, setNewUser] = useState({
    name: "",
    ssn: "",
    phone: "",
    address: "",
    registrationTime: moment().format("YYYY-MM-DD HH:mm:ss")
  });

  useEffect(() => {
    if (!localStorage.getItem("admin")) {
      window.location.href = "/";
    }
    setAdmin(localStorage.getItem("admin"));

    const fetchEvents = async () => {
      try {
        const response = await axios.get("https://xn--bb0bp9it32a1kcc8ci3c.com/api/events");
        setEvents(response.data);
        if (response.data.length > 0) {
          setSelectedEvent(response.data[0].id);
          fetchUsers(response.data[0].id);
        }
      } catch (error) {
        console.error("Error fetching events:", error);
      }
    };

    fetchEvents();
  }, []);

  const fetchUsers = async (eventId) => {
    try {
      const response = await axios.get(`https://xn--bb0bp9it32a1kcc8ci3c.com/api/events/${eventId}/users`);
      setUsers((prevUsers) => ({
        ...prevUsers,
        [eventId]: response.data
      }));
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const handleAddUser = async () => {
    try {
      const formattedUser = {
        ...newUser,
        registrationTime: moment().format("YYYY-MM-DD HH:mm:ss")
      };
      await axios.post(`https://xn--bb0bp9it32a1kcc8ci3c.com/api/events/${selectedEvent}/users`, formattedUser);
      setUsers((prevUsers) => ({
        ...prevUsers,
        [selectedEvent]: [...prevUsers[selectedEvent], formattedUser]
      }));
      setNewUser({
        name: "",
        ssn: "",
        phone: "",
        address: "",
        registrationTime: moment().format("YYYY-MM-DD HH:mm:ss")
      });
      setIsModalOpen(false);
    } catch (error) {
      console.error("Error adding user:", error);
    }
  };

  const handlePaymentToggle = async (userId, currentStatus) => {
    const newStatus = !currentStatus;
    const confirmationMessage = newStatus
      ? "입금이 확정됩니다."
      : "입금 확정이 취소됩니다.";
    Modal.confirm({
      title: "입금 상태 변경",
      content: confirmationMessage,
      onOk: async () => {
        try {
          await axios.put(`https://xn--bb0bp9it32a1kcc8ci3c.com/api/participants/${userId}/payment`, {
            paymentConfirmed: newStatus
          });
          message.success(`입금 상태가 ${newStatus ? "확정" : "취소"}되었습니다.`);
          fetchUsers(selectedEvent); // 업데이트된 유저 목록을 다시 불러옵니다.
        } catch (error) {
          console.error("Error updating payment status:", error);
          message.error("입금 상태 업데이트 중 오류가 발생했습니다.");
        }
      }
    });
  };

  const columns = [
    {
      title: '#',
      label: '#',
      dataIndex: 'index',
      key: 'index',
      render: (_, __, index) => index + 1
    },
    {
      title: '성명',
      label: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: '주민번호 앞자리',
      label: 'SSN',
      dataIndex: 'ssn',
      key: 'ssn',
    },
    {
      title: '연락처',
      label: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: '주소',
      label: 'Address',
      dataIndex: 'address',
      key: 'address',
    },
    {
      title: '등록 시간',
      label: 'Registration Time',
      dataIndex: 'registrationTime',
      key: 'registrationTime',
    },
    {
      title: '입금 상태',
      label: 'Payment Status',
      dataIndex: 'paymentConfirmed',
      key: 'paymentConfirmed',
      render: (text, record) => (
        <Button
          type={record.paymentConfirmed ? "primary" : "default"}
          onClick={() => handlePaymentToggle(record.id, record.paymentConfirmed)}
        >
          {record.paymentConfirmed ? "입금 확정" : "미입금"}
        </Button>
      )
    }
  ];

  return (
    <div className="admin-page" style={{ padding: '20px' }}>
      <Modal
        title="유저 추가"
        open={isModalOpen}
        onOk={handleAddUser}
        onCancel={() => setIsModalOpen(false)}
      >
        <div style={{ marginBottom: '10px' }}>
          <label>성명:</label>
          <Input
            value={newUser.name}
            onChange={(e) => setNewUser({ ...newUser, name: e.target.value })}
            style={{ marginBottom: '10px' }}
          />
        </div>
        <div style={{ marginBottom: '10px' }}>
          <label>주민번호 앞자리:</label>
          <Input
            value={newUser.ssn}
            onChange={(e) => setNewUser({ ...newUser, ssn: e.target.value })}
            style={{ marginBottom: '10px' }}
          />
        </div>
        <div style={{ marginBottom: '10px' }}>
          <label>연락처:</label>
          <Input
            value={newUser.phone}
            onChange={(e) => setNewUser({ ...newUser, phone: e.target.value })}
            style={{ marginBottom: '10px' }}
          />
        </div>
        <div style={{ marginBottom: '10px' }}>
          <label>주소:</label>
          <Input
            value={newUser.address}
            onChange={(e) => setNewUser({ ...newUser, address: e.target.value })}
            style={{ marginBottom: '10px' }}
          />
        </div>
      </Modal>
      <div style={{ fontSize: '24px', fontWeight: 'bold', marginBottom: '20px' }}>대회 등록 인원 관리</div>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
        <div>
          대회명 선택: 
          <Select 
            value={selectedEvent}
            onChange={(value) => {
              setSelectedEvent(value);
              fetchUsers(value);
            }}
            style={{ width: 300, marginRight: '10px' }}
          >
            {events.map(event => (
              <Option key={event.id} value={event.id}>{event.eventName}</Option>
            ))}
          </Select>
          <Input
            style={{ width: '200px', marginRight: '10px' }}
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
          />
          <Button onClick={() => {}}>검색</Button>
        </div>
        <Button type="primary" onClick={() => setIsModalOpen(true)}>유저 추가하기</Button>
      </div>
      <Table dataSource={users[selectedEvent]} columns={columns} rowKey="index" />
    </div>
  );
};

export default Pati;
